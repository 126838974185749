<div fxLayout="column">
    <div class="container-login" fxLayout="row" fxLayoutAlign="center">
        <div [ngClass]="{'shake': shakeIt, 'card': true}">
            <h4 class="card-header" fxLayoutAlign="center"><img width="300" src="{{url}}assets/images/sunshine-logo.svg" alt="">
            </h4>
            <div class="card-body">
                <form name="loginForm" [formGroup]="authForm" (ngSubmit)="login()">
                    <div class="form-group">
                        <input type="email" class="form-control form-control-lg" placeholder="Email"
                            formControlName="email">
                    </div>
                    <div class="form-group">
                        <input type="password" class="form-control form-control-lg" placeholder="Password"
                            formControlName="password">
                    </div>
                    <button type="submit" class="btn sign-btn"
                        [ngClass]="{'red' : buttonError, 'green': !buttonError}">{{isLoading ? buttonLog: 'Sign in'
                        }}</button>
                </form>
                <div class="forgot-link" fxLayoutAlign="center center">
                    <a href="" [routerLink]="['/login-forgot']">Forgot Password?</a>
                </div>                          
            </div>
            <div class="trouble-loging-info">
                <div>
                    <span class="trouble-login-icon"><i class="fas fa-question"></i>&nbsp;</span>
                    <span style="font-weight: bold;color:#659E71;">&nbsp;Need Support?</span>
                    <span style="color:#364245;">&nbsp;Contact Us at&nbsp;</span>
                    <a href="mailto:edproducts@sunshinecottage.org">edproducts@sunshinecottage.org</a>
                </div>              
            </div>
        </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="center center" class="py-5 updateAlt">
        <div fxFlex="78">
            <div fxLayout="column" fxLayoutAlign="center start" class="bannerAlt rounded bg-light p-4">
                <div style="text-decoration: underline;color:#28a745;font-size: larger;font-weight: bold;padding-top: 20px;width: 100%;"
                    fxLayout="row" fxLayoutAlign="center">
                    ATTENTION:
                </div>
                <div fxLayout="column" fxLayoutAlign="center start">
                    <div>
                        <p style="padding-top: 10px;">Dear eCASLLS Users,</p>
                    </div>

                    <p style="font-weight: normal; margin: 0;padding-top: 10px;">
                        To help protect users privacy, we have recently launched <strong>a new feature that automatically logs out users after 8 hours of inactivity</strong>.
                    </p>
                    <p style="font-weight: normal; margin: 0;padding-top: 10px;">
                        If you experience issues logging into your account, we strongly recommend that you perform <a href="https://www.ecaslls.com/platform/pdf/Sunshine_eCASLLS_Full_Cache_Clear.pdf">Full Clear of All Browsing Data</a>, which will help ensure that your browser loads the latest version of the eCASLLS platform.
                    </p>
                    <p style="font-weight: normal; margin: 0;padding-top: 10px;">
                        If you have any questions or lingering issues, please contact our team <a href="mailto:edproducts@sunshinecottage.org">edproducts@sunshinecottage.org</a>.
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>